var render, staticRenderFns
import script from "./table.vue?vue&type=script&lang=js&"
export * from "./table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ef1b8a0')) {
      api.createRecord('0ef1b8a0', component.options)
    } else {
      api.reload('0ef1b8a0', component.options)
    }
    
  }
}
component.options.__file = "found/views/kms_new/views/document_capture_log/table.vue"
export default component.exports